<template>
  <div
    class="searchResult"
    v-watermark
  >
    <commonNavBar :title="'任务搜索'"></commonNavBar>
    <div class="searchBox">
      <form action="/">
        <van-search
          :value="$route.params.text"
          show-action
          left-icon=""
          :clearable="false"
          readonly
          @click="$router.back()"
        >
          <template #action>
            <div @click="$router.back()">取消</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                :value="+$route.params.type"
                :options="option1"
                disabled
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <div class="searchBar">
        <span class="label">搜索结果:共xx条</span>
        <svg-icon
          icon-class="delet"
          className="myIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'searchResult',
  data() {
    return {

      option1: [
        { text: '单位', value: 0 },
        { text: '任务单', value: 1 }
      ],
      historyList: []
    }
  }
}
</script>

<style lang="scss" scoped>
.searchResult {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 10px;
            }
          }
        }
      }

      .van-dropdown-menu__item--disabled {
        .van-dropdown-menu__title {
          color: #323233;
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        margin-bottom: 10px;

        .label {
          font-size: 14px;
          color: #aaaaaa;
        }

        .myIcon {
          width: 24px;
          height: 24px;
        }
      }

    }
  }
}
</style>
